import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Header_two from "../Header_two";
import More_ifo_menu from "./More_ifo_menu";
import AuthService from "../../service/authservice";
import "./For_info.css";
import { getFileExtension, getFileIcon } from "../CommonService/fileDetails";
const Other_Doc = () => {
  const [OtherDocs, SetOtherDocs] = useState([
    {
      generalMeetingNotice: [],
      others: [],
      liquidity: [],
    },
  ]);
  const [other, setothers] = useState([])
  let [fileDownload, SetFileDownload] = useState({
    base64String: "",
    fileType: "",
    fileName: "",
  });
  let OnChangeDownloadHandler = (arg) => {
    let filename = arg;
    AuthService.GetServiceCall("MbfsReports/DownloadFile?FilePath=" + filename)
      .then((res) => {
        if (res != undefined) {
          SetFileDownload(res);
        }
      })
      .catch((err) => console.log("Error:", err));
  };
  useEffect(() => {
    if (fileDownload.base64String != null && fileDownload.base64String != "") {
      let a = document.createElement("A");
      a.href =
        `data:${fileDownload.fileType};base64,` + fileDownload.base64String;
      a.download = fileDownload.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [fileDownload]);
  useEffect(() => {
    const mbfsToken = localStorage.getItem("MBFSIUser")
    if (mbfsToken != null) {
      handlerGetFile();
    }
    else {
      setTimeout(() => {
        handlerGetFile();
      }, 2000)
    }
  }, []);
  const handlerGetFile = () => {
    AuthService.GetServiceCall("MbfsReports/GetOtherDocuments")
      .then((res) => {
        if (res != null) {
          SetOtherDocs(res);
          const filterdata = res.others[0].report.filter((item) => item.heading !== "CSR Policy");
          setothers(filterdata)
        }
      })
      .catch((err) => {
        SetOtherDocs(null)
      });
  };
  return (
    <div>
      <Header />
      <Header_two />
      <More_ifo_menu />
      <div className="container pad_cnt_mob adj">
        <h2 className="cnt_us_main_hed">Grievance Redressal Mechanism</h2>
        <p className="f_s_r_q_c_o">
          For Financial Services related queries/complaints only.
        </p>
        <div className="add_le_1 on_O">
          <p>
            <b>Level 1</b>
          </p>
          <p>Any one of the following</p>
          <p>
            <b>Call us:</b>
          </p>
          <p>
            Customer Service Centre:  <a href="tel:1800-212-3374"> 1800 212 3374 </a>  <span className="slash_span"> / </span> <a href="tel:1800-212-2237"> 1800 212 2237 </a> from 09:00
            – 18:00 (Mon – Fri) & 09:00 – 13:00 (Sat), except public holidays.
          </p>
          <p>
            <b>Email Id:</b>
          </p>
          <p>
            <a href="mailto:mbfsindia@mercedes-benz.com">
              mbfsindia@mercedes-benz.com
            </a>
          </p>
          <p>
            You can also access information related to your account by visiting
            customer online service portal. COS Link.
          </p>
          <p>
            Passenger Cars: <a href="https://mymbfs.com/">www.mymbfs.com</a>
          </p>
        </div>
        <div className="add_le_1">
          <p>
            <b>Level 2</b>
          </p>
          <p>
            If the complaint is unresolved within 10 working days, please contact our Grievance Redressal Officer,
            Mr. Gurinder Singh with the reference number given in <br /> Level 1 – Email id:
            mbfsigrievanceofficer@mercedes-benz.com
          </p>
          <p>
            id:{" "}
            <a href="mailto:mbfsigrievanceofficer@mercedes-benz.com">
              {" "}
              mbfsigrievanceofficer@mercedes-benz.com
            </a>
          </p>
        </div>
        <div className="or_txt">(OR)</div>
        <p className="wri_cus_txt">Write to Customer Service Department</p>
        <div className="first_addd two">
          <p className="add_b_txt">
            <b>Registered address:</b>
          </p>
          <p>Mercedes-Benz Financial Services India Pvt. Ltd.</p>
          <p> 5th Floor, Plot 8, Baashyam Willow Square 9 & 10,</p>
          <p>First Street, Thiru ViKa Industrial Estate, Guindy,</p>
          <p>Chennai - 600032</p>
          <p>Tamil Nadu, India.</p>
        </div>
        <div className="first_addd two l">
          <p className="add_b_txt">
            {" "}
            <b>Correspondence Address:</b>
          </p>
          <p>Mercedes-Benz Financial Services India Pvt. Ltd.</p>
          <p> E-3, MIDC Chakan, Phase III,</p>
          <p> Chakan Industrial Area, Kuruli & Nighoje,</p>
          <p> Taluka Khed, Pune - 410501</p>
          <p> Maharashtra, India</p>
          <p> Telephone Number: (+91) 21 3567 3000</p>
        </div>

        <h3 className="top_name_flt">General Meeting Notice</h3>
        {OtherDocs != null && OtherDocs.generalMeetingNotice != null && OtherDocs.generalMeetingNotice.length > 0 && (
          <div className="accordion" id="accordion_gmn">
            {OtherDocs.generalMeetingNotice.map((gItems, gId) => {
              return (
                <div className="accordion-item accordion-item_an" key={gId}>
                  <button
                    className="accordion-button ac_btn collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapsetwo_fr${gId}`}
                  >
                    <h3>
                      {gItems.heading} <span>{gItems.report.length == 1 ? `(${gItems.report.length} file)` : `(${gItems.report.length} files)`}</span>
                    </h3>
                  </button>
                  <div id={`collapsetwo_fr${gId}`} className="accordion-collapse collapse" data-bs-parent="#accordion_gmn">
                    <div className="accordion-body ar">
                      <h3 className="brd_name">
                        <span>Mercedes-Benz</span> Financial Services India
                      </h3>
                      <h1 className="ye_an">{gItems.heading}</h1>
                      <ul className="inside_st_ex gmn">
                        {gItems.report != null &&
                          gItems.report.map((fileItems, fileId) => {
                            return <li key={fileId}>{fileItems.heading}
                              <p className="dwn_pdf se">
                                <a
                                  onClick={() =>
                                    OnChangeDownloadHandler(fileItems.filePath)
                                  }
                                >
                                  <i className={`fa-solid ${getFileIcon(fileItems.filePath)}`}></i>
                                  <span>{getFileExtension(fileItems.filePath)}</span>
                                </a>
                              </p>
                            </li>
                          })

                        }


                        {/* <li>EGM Notice - October 2020</li>
                        <p className="dwn_pdf se">
                          <a
                            target="_blank"
                            href="https://www.mercedes-benz-financialservices.in/en/Other-Documents/EGMNotice_October2020.pdf"
                          >
                            <i className="fa-solid fa-file-pdf"></i>
                            <span>Download PDF</span>
                          </a>
                        </p> */}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="accordion-item accordion-item_an">

                        <button className="accordion-button ac_btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapsetwo_gm_4">
                            <h3>Year Of 2021 <span>(1 file)</span></h3>
                        </button>
                        <div id="collapsetwo_gm_4" className="accordion-collapse collapse" data-bs-parent="#accordion_gmn">
                            <div className="accordion-body ar">
                                <h3 className='brd_name'><span>Mercedes-Benz</span> Financial Services India</h3>
                                <h1 className='ye_an'>Year Of 2021</h1>
                                <ul className='inside_st_ex gmn'>
                                    <li>AGM Notice - 2021</li>
                                    <p className='dwn_pdf se'><a target="_blank" href="https://www.mercedes-benz-financialservices.in/en/Other-Documents/AGMNotice_2021.pdf"><i className="fa-solid fa-file-pdf"></i><span>Download PDF</span></a></p>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="accordion-item accordion-item_an">

                        <button className="accordion-button ac_btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree_gm_4">
                            <h3>Year Of 2022 <span>(2 files)</span></h3>
                        </button>
                        <div id="collapsethree_gm_4" className="accordion-collapse collapse" data-bs-parent="#accordion_gmn">
                            <div className="accordion-body ar">
                                <h3 className='brd_name'><span>Mercedes-Benz</span> Financial Services India</h3>
                                <h1 className='ye_an'>Year Of 2022</h1>
                                <ul className='inside_st_ex gmn'>
                                    <li>AGM Notice - 2022</li>
                                    <p className='dwn_pdf se'><a target="_blank" href="https://www.mercedes-benz-financialservices.in/en/Other-Documents/DFSI%2012th%20AGM%20Notice%20and%20Agenda.pdf"><i className="fa-solid fa-file-pdf"></i><span>Download PDF</span></a></p>
                                    <li>EGM Notice - 2022</li>
                                    <p className='dwn_pdf se'><a target="_blank" href="https://www.mercedes-benz-financialservices.in/en/Other-Documents/EGM%20Notice.pdf"><i className="fa-solid fa-file-pdf"></i><span>Download PDF</span></a></p>
                                </ul>
                            </div>
                        </div>

                    </div> */}
          </div>
        )}

        <div className="ind_link_pdf">
          {other?.map((otherItems, otherId) => {
            return <div className="ind_link_pdf_v" key={otherId}>
              <h3>{otherItems.heading}</h3>
              <p className="dwn_pdf se">
                <a onClick={() => OnChangeDownloadHandler(otherItems.filePath)}><i className={`fa-solid ${getFileIcon(otherItems.filePath)}`}></i><span>{getFileExtension(otherItems.filePath)}</span></a>
              </p>
            </div>
          })
          }


          {/*           
          <div className="ind_link_pdf_v">
            <h3>Statutory Auditors Appointment Policy</h3>
            <p className="dwn_pdf se">
              <a
                target="_blank"
                href="https://www.mercedes-benz-financialservices.in/en/Other-Documents/DFSI%20-%20Statutory%20Auditors%20Appointment%20Policy%20V1.pdf"
              >
                <i className="fa-solid fa-file-pdf"></i>
                <span>Download PDF</span>
              </a>
            </p>
          </div> */}
        </div>

        
       
        <h6 className="int_sub_txt two">
          Liquidity Risk Management Framework Disclosures
        </h6>
        {OtherDocs?.liquidity != null &&
          OtherDocs.liquidity.map((liquidityItems, liquidityId) => {
            return <div className="liquid_rs" key={liquidityId}>
              <h3>{liquidityItems.heading}</h3>
              {liquidityItems.report.map((liquidRepots, liquidrepIs) => {
                return <div className="liq_mnths" key={liquidrepIs}>
                  <p>{liquidRepots.heading}</p>
                  <p className="dwn_pdf se">
                    <a onClick={() => OnChangeDownloadHandler(liquidRepots.filePath)}><i className={`fa-solid ${getFileIcon(liquidRepots.filePath)}`}></i><span>{getFileExtension(liquidRepots.filePath)}</span></a>
                  </p>
                </div>
              })
              }
            </div>
          })

        }
        <h6 className="int_sub_txt two">Nodal Officer</h6>
        <div className="nodal_dd">
          <p>
            <b>Name:</b>
          </p>
          <p>Ms. Anita Iyer</p>
        </div>
        <div className="nodal_dd 2">
          <p>
            <b>Address:</b>
          </p>
          <p>E-3, MIDC Chakan, Phase III,</p>
          <p>Chakan Industrial Area,</p>
          <p>Kuruli & Nighoje, Taluka : Khed,</p>
          <p>Pune – 410 501,</p>
          <p>Maharashtra- India</p>
        </div>
        <div className="nodal_dd 3">
          <p>
            <b>Telephone:</b>
          </p>
          <p>02135 637377</p>
        </div>
        <div className="nodal_dd fr">
          <p>
            <b>Email id:</b>
          </p>
          <p>
            <a href="mailto:nodalofficer@mercedes-benz.com">
              nodalofficer@mercedes-benz.com
            </a>
          </p>
        </div>
        {OtherDocs?.discontinuedOutsourcedVendors != null &&
          <div>
            <h6 className="int_sub_txt two">
              List of Discontinued Outsourced Vendors
            </h6>
            <p className="f_s_r_q_c_o">Select Year to Download</p>
            <div className="liquid_rs">
              <div className="lang_box">
                {OtherDocs.discontinuedOutsourcedVendors != null && OtherDocs.discontinuedOutsourcedVendors[0].report.map((discontinuedOutsourcedVendorsRep, discontinuedOutsourcedVendorsRepId) => {
                  return <button key={discontinuedOutsourcedVendorsRepId} className="box_in_btn date_bx" onClick={() => OnChangeDownloadHandler(discontinuedOutsourcedVendorsRep.filePath)}><i className="fa-solid fa-calendar"></i>{discontinuedOutsourcedVendorsRep.heading}</button>
                })
                }
              </div>
            </div>
          </div>
        }
        {OtherDocs?.fairpractice != null &&
          <div>
            <h6 className="int_sub_txt two">
              Fairpractice Code
            </h6>
            <p className="f_s_r_q_c_o">For Fair Practice Code Policy Other Languages</p>
            <div className="liquid_rs">
              <div className="lang_box">
                {OtherDocs.fairpractice != null && OtherDocs.fairpractice[0].report.map((fairpracticeRep, fairpracticeRepId) => {
                  return <button key={fairpracticeRepId} className="box_in_btn" onClick={() => OnChangeDownloadHandler(fairpracticeRep.filePath)}>{`Download ${fairpracticeRep.heading}`}</button>
                })
                }
              </div>
            </div>
          </div>
        }
      </div>
      <Footer />
    </div>
  );
};

export default Other_Doc;

