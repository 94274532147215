import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Header_two from '../Header_two'
import More_ifo_menu from './More_ifo_menu'
import AuthService from '../../service/authservice'
import { getFileExtension, getFileIcon } from "../CommonService/fileDetails";
const CSR_plan = () => {
    const [other, setothers] = useState([])
    useEffect(() => {
        const mbfsToken = localStorage.getItem("MBFSIUser")
        if (mbfsToken != null) {
            handlerGetFile();
        }
        else {
            setTimeout(() => {
                handlerGetFile();
            }, 2000)
        }
    }, []);
    const handlerGetFile = () => {
        AuthService.GetServiceCall("MbfsReports/GetOtherDocuments")
            .then((res) => {
                if (res != null) {
                    const filterdata = res.others[0].report.filter((item) => item.heading === "CSR Policy");
                    setothers(filterdata)
                }
            })
            .catch((err) => {
                setothers(null)
            });
    };
    let [fileDownload, SetFileDownload] = useState({
        base64String: "",
        fileType: "",
        fileName: "",
    });
    const Handledownlaodfile = () => {
        AuthService.GetServiceCall("MbfsReports/Download_CSR_Plan")
            .then((res) => {
                if (res != undefined) {
                    console.log(res)
                    const href = `data:application/pdf;base64,${res.filePath}`;
                    const link = document.createElement('a');
                    link.href = href;
                    link.download = res.fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                }
            })
            .catch((err) => console.log("Error:", err));
    }
    let OnChangeDownloadHandler = (arg) => {
        let filename = arg;
        AuthService.GetServiceCall("MbfsReports/DownloadFile?FilePath=" + filename)
            .then((res) => {
                if (res != undefined) {
                    SetFileDownload(res);
                }
            })
            .catch((err) => console.log("Error:", err));
    };
    useEffect(() => {
        if (fileDownload.base64String != null && fileDownload.base64String != "") {
            let a = document.createElement("A");
            a.href =
                `data:${fileDownload.fileType};base64,` + fileDownload.base64String;
            a.download = fileDownload.fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }, [fileDownload]);


    return (
        <div>
            <Header />
            <Header_two />
            <More_ifo_menu />
            <div className="container pad_cnt_mob adj">
                <h2 className="cnt_us_main_hed">Corporate Social Responsibility</h2>
                <div className="ind_link_pdf_v">
                    <h3>Composition of CSR Committee as on date</h3>
                    <table className="dmcookies_tbl ">
                        <thead>
                            <tr>
                                <th>Name of the Director</th>
                                <th>Position</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Mr. Brendon James Sissing</td>
                                <td>Chairperson</td>
                            </tr>
                            <tr>
                                <td>Mr. Ingo Krueger</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>Mr. Harish Chand Jain</td>
                                <td>Member</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    {other?.map((otherItems, otherId) => {
                        return <div className="ind_link_pdf_v" key={otherId}>
                            <h3>{otherItems.heading}</h3>
                            <p className="dwn_pdf se">
                                <a onClick={() => OnChangeDownloadHandler(otherItems.filePath)}><i className={`fa-solid ${getFileIcon(otherItems.filePath)}`}></i><span>{getFileExtension(otherItems.filePath)}</span></a>
                            </p>
                        </div>
                    })
                    }
                </div>

                <div>
                    <h3>CSR Projects Approved by the Board</h3>
                    <div className='csr_p_div'>
                        <table className="dmcookies_tbl csr_table">
                            <thead>
                                <tr>
                                    <th colSpan={4} style={{ textAlign: 'center', borderBottom: '1px solid #fff' }}>MERCEDES-BENZ FINANCIAL SERVICES INDIA PRIVATE LIMITED</th>
                                </tr>
                                <tr>
                                    <th colSpan={4} style={{ textAlign: 'center', borderBottom: '1px solid #fff' }}>List of Approved CSR Projects for FY 2023-24</th>
                                </tr>
                                <tr>
                                    <th>Sr.No</th>
                                    <th className="adjs_width_tab_on">Items from the List of Activities from Schedule VII</th>
                                    <th>Name of the Project</th>
                                    <th className="adjs_width_tab_tw">Amount to be spent</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Promoting education, including special education and employment enhancing vocation skills especially among children,
                                        women, elderly, and the differently abled and livelihood enhancement projects.
                                    </td>
                                    <td>Refurbishment of School located at Moroshi, Khed Taluka.</td>
                                    <td>INR 9,875,621/-</td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>Promoting gender equality, empowering women, setting up homes and hostels for women and orphans; setting up old age homes, day care centers and such other facilities for
                                        senior citizens and measures for reducing inequalities faced by socially and economically backward groups.
                                    </td>
                                    <td>Empowering Women through Skill Development & Child Development.</td>
                                    <td>INR 1,678,232/-</td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>Ensuring environmental sustainability, ecological balance, protection of flora and fauna, animal welfare, agroforestry, conservation of natural resources and maintaining quality of soil, air and water
                                        [including contribution to the Clean Ganga Fund set-up by the Central Government for rejuvenation of river Ganga]
                                    </td>
                                    <td>Plantation of 8100 trees at Tulapur.</td>
                                    <td>INR 54,461,47.50/-</td>
                                </tr>
                                <tr>
                                    <td colSpan={3} style={{ textAlign: 'center', fontWeight: 700 }}>TOTAL AMOUNT APPROVED FOR FY 2023-24</td>
                                    <td style={{ fontWeight: 700 }}>INR 17,000,000.50/-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='ind_link_pdf_v'>
                    <h3>CSR Annual Action Plan</h3>
                    <p className="dwn_pdf se">
                        <a onClick={Handledownlaodfile}><i className={"fa-solid fa-file"}></i><span>Download PDF</span></a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CSR_plan