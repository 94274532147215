import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'


const More_ifo_menu = () => {


    const animate_1 = () => {
        var zmon = document.querySelectorAll(".top_tw_mnu_flt");
        var scroll = window.pageYOffset;

        for (var i = 0; i < zmon.length; i++) {
            if (scroll > 165) {
                zmon[i].classList.add("activeing");
            } else {
                zmon[i].classList.remove("activeing");
            }
            // console.log(scroll)
            // console.log(zmon)
        }
    }

    window.addEventListener("scroll", animate_1);


    return (
        <div>
            <div className='container-fluid top_tw_mnu_flt'>
                <div className='container d-none d-md-block'>
                    <div className='our_cmp_top_m for_info'>
                        <NavLink end className='our_cmp_l_n one' to='/More_info' >Contact Us</NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/Legal_notice' >Legal notice</NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/Cookies' >Cookies</NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/Privacy_s' >Privacy statement</NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/Fradulent' >Fraudulent call alert</NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/Framework_rs' >Resolutions Framework 2.0 </NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/FAQ' >FAQs</NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/SARFAESI_ACT' >Assets possessed under SARFAESI</NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/CSR_plan' >CSR</NavLink>
                        <NavLink className='our_cmp_l_n' to='/More_info/Other_document' >Other Documents And Policies</NavLink>
                    </div>
                </div>
                <div className='container fincial d-block d-md-none'>
                    <p data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop_3"><span>For More Info</span><i className="fa-solid fa-angle-down"></i></p>
                </div>
            </div>
        </div>
    )
}

export default More_ifo_menu