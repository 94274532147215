
import Home_page from "./Components/Home_page/Home_page";
import { BrowserRouter, Routes, Route, useLocation, HashRouter, } from "react-router-dom";
import Our_company from "./Components/Our_company_page/Our_company";
import Our_prod from "./Components/Our_product_page/Our_prod";
import Leadership from "./Components/Leadership_page/Leadership";
import Investor from "./Components/Our_company_page/Investor";
import More_info from "./Components/For_info_page/More_info";
import Legal_notice from "./Components/For_info_page/Legal_notice";
import Cookies from "./Components/For_info_page/Cookies";
import Privacy_s from "./Components/For_info_page/Privacy_s";
import Fradulent from "./Components/For_info_page/Fradulent";
import Framework_rs from "./Components/For_info_page/Framework_rs";
import "./Fontstyle.css"
import FAQ from "./Components/For_info_page/FAQ";
import { useLayoutEffect } from 'react'
import Vission from "./Components/Our_company_page/Vission";
import Login_form from "./Components/Login_form";
import Download_table from "./Components/Download_table";
import Other_Doc from "./Components/For_info_page/Other_Doc";
import ProtectedRoutes from "./ProtectedRoute";
import AuthService from './service/authservice'
import Sarfaesi_act from "./Components/For_info_page/Sarfaesi_act";
import Chat_bot from "./Components/Chat_bot/Chat_bot";
import CSR_plan from "./Components/For_info_page/CSR_plan";



const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
    // console.log(children)
  }, [location.pathname]);
  return children

}



function App() {
  if (localStorage.getItem('MBFSIUser')) {
    // alert("token exist");
    const response = AuthService.GetWithExpiry('MBFSIUser');
    if (response == null) {
      AuthService.createPublicUserToken();
    }
  }
  else {
    // alert("token is not available")
    AuthService.createPublicUserToken();
  }
  // if(!localStorage.getItem('MBFSIUser')){
  //          createPublicUserToken;
  //    }
  return (
    <div className="App">

      <BrowserRouter>
        <Wrapper>
          <Routes >
            <Route exact path="/Our_product" element={<Our_prod />} />
            <Route exact path="/Our_company" element={<Our_company />} />
            <Route exact path="/More_info" element={<More_info />} />
            <Route exact path="/Our_company/leadership" element={<Leadership />} />
            <Route exact path="/Our_company/Vission" element={<Vission />} />
            <Route exact path="/Our_company/Investor" element={<Investor />} />
            <Route exact path="/More_info/Legal_notice" element={<Legal_notice />} />
            <Route exact path="/More_info/Cookies" element={<Cookies />} />
            <Route exact path="/More_info/Privacy_s" element={<Privacy_s />} />
            <Route exact path="/More_info/Fradulent" element={<Fradulent />} />
            <Route exact path="/More_info/Framework_rs" element={<Framework_rs />} />
            <Route exact path="/More_info/FAQ" element={<FAQ />} />
            <Route exact path="/More_info/CSR_plan" element={<CSR_plan />} />
            <Route exact path="/More_info/SARFAESI_ACT" element={<Sarfaesi_act />} />

            <Route exact path="/" element={<Home_page />} />
            <Route exact path="/adminLogin" element={<Login_form />} />
            <Route exact path='/dashboard' element={<ProtectedRoutes />}>
              <Route path="/dashboard/Home" element={<Download_table />} />
            </Route>
            <Route path="/More_info/Other_document" element={<Other_Doc />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>

      {/* <Chat_bot /> */}

    </div>
  );
}

export default App;